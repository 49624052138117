import React from "react";
import { ReactComponent as Brand } from "./Images/logo.svg";
import { ReactComponent as Hamburger } from "./Images/hamburger.svg";
import { NavLink } from "react-router-dom";
import "./navbar.css";

function Header() {
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <div className="siteLogo">
            {/* <Brand /> */}
            <img src={require("./Images/pnlogoslu.png")}  className="companylogo"/>
          </div>
          {/* <div className="siteName">Sky Lift UAE</div> */}
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="/projects">Projects</a>
            </li>
            <li>
              <a href="#aboutUs">About</a>
            </li>
            <li>
              <a href="#contactUs">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
