import React from "react";

function Footer() {
  return (
    <div className="footerWrapper">
      Copyright © 2022 Sky Lift UAE All Right Reserved.
    </div>
  );
}

export default Footer;
