import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./App.css";
import Header from "./Header";
import ContactUs from "./ContactUs";
import About from "./About";
import Footer from "./Footer";
import Favicon from "react-favicon";

function App() {

  const [faviconUrl, setFaviconUrl] = React.useState(require("./Images/pnlogoslu.png"));
  React.useEffect(() => {
    document.title = "SkyLiftUAE";
  }, []);

  return (
    <div className="App">
       <div className="modalboxvideocover">
          <div className="modalboxvideoinnerbox">
            <div className="modalboxvideoinnertop">
              X
            </div>
            <div className="modalboxvideoinnervideocover">
              <iframe className="modalboxvideoiframe" src={require("./Images/award.mp4")}>
              
              </iframe>
            </div>
          </div>
       </div>
      <Favicon url={faviconUrl} />
      <Header />
      <div className="pageWrapper" id="home">
        <div className="ImageCover">
          <Carousel infiniteLoop useKeyboardArrows autoPlay>
          <div>
              <img src={require("./Images/lift1.jpg")} />
            </div>
            <div>
              <img src={require("./Images/lift2.jpg")} />
            </div>
            <div>
              <img src={require("./Images/lift3.jpg")} />
            </div>
            <div>
              <img src={require("./Images/lift4.jpeg")} />
            </div>
          </Carousel>
        </div>
        <div className="TextCover">
          <div className="DataCover">
            <div className="companyHeader">The Service Professionals</div>
            <div className="Information">
              Performs technical inspections and repairs of rack and pinion
              elevator equipment and systems to ensure conformity with
              maintenance standards, recommending adjustments as necessary to
              preserve equipment integrity or react to changing operating
              conditions.
            </div>
          </div>
        </div>
      </div>
      <div className="apprecitionsBox" id="appbox">
        <div className="apprecitionsBoxcover" id="appbox">
          <div className="apprecitionsBoxlogo" id="">
            <img src={require("./Images/Mitsubishi_Electric_logo.png")}  className="companylogo apprecitionsBoxclogo"/>
          </div>
          <div className="apprecitionsBoxtext" id="">
              Won the HSE Excellence Award for the Best Sub-contractor of Year 2023
          </div>
          <div className="apprecitionsBoxvideo" id="">
            {/* <div className="apprecitionsBoxvideologo" id=""> */}
              {/* <img src={require("./Images/ytlogo.png")}  className="companylogo"/> */}
              <iframe className="modalboxvideoiframe" src={require("./Images/award.mp4")}>
              
              </iframe>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="contactUsMainBox" id="contactUs">
        <ContactUs />
      </div>
      <div className="aboutUsMainBox" id="aboutUs">
        <About />
      </div>
      <div className="footerMainBox">
        <Footer />
      </div>
    </div>
  );
}

export default App;
