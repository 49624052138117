import React from "react";
import emailjs from "emailjs-com";

function ContactUs() {
  const [name, setname] = React.useState("");
  const [phone, setphone] = React.useState("");
  const [email, setemail] = React.useState("");
  const [message, setmessage] = React.useState("");

  const sendMail = () => {
    emailjs
      .send(
        "service_xzclica",
        "template_u056ibq",
        {
          from_name: name,
          message: message,
          phone: phone,
          email: email,
        },
        "NC6vprKgA1-EFslm1"
      )
      .then((result) => {
        alert("Message sent successfully");
        setname("");
        setemail("");
        setphone("");
        setmessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="ContactUsWrapper">
      <div className="ContactBoxWrapper">
        <div className="ContactBoxContactWrapper">
          <div className="ContactBoxTitle">Leave Us a Message</div>
          <div className="ContactBoxTextWrapper">
            <input
              className="InputBox firstBox"
              placeholder="NAME*"
              value={name}
              defaultValue={""}
              onChange={(e) => setname(e.currentTarget.value)}
            />
            <input
              className="InputBox secondBox"
              placeholder="EMAIL*"
              defaultValue={""}
              value={email}
              onChange={(e) => setemail(e.currentTarget.value)}
              // value={email}
            />
            <input
              className="InputBox thirdBox"
              placeholder="PHONE*"
              defaultValue={""}
              value={phone}
              onChange={(e) => setphone(e.currentTarget.value)}
              // value={phone}
            />
            <textarea
              className="TextBox"
              placeholder="MESSAGE"
              defaultValue={""}
              value={message}
              onChange={(e) => setmessage(e.currentTarget.value)}
              // value={message}
            />
            <div className="ContactButtonWrapper">
              <input
                type="button"
                className="ContactButton"
                value={"Send message"}
                onClick={() => sendMail()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ContactUsData">
        <div className="ContactUsTitle">Contact Us</div>
        <div className="ContactUsDataWrapper">
          <div className="ContactUsDataIcon">
            <img
              src={require("./Images/location.png")}
              className="ContactUsDataIconImg"
            />
          </div>
          <div className="ContactUsDatatext">
            <div className="ContactUsDatatextHead">Location :</div>
            <div className="ContactUsDatatextValue">Dubai, UAE.</div>
          </div>
        </div>
        <div className="ContactUsDataWrapper">
          <div className="ContactUsDataIcon">
            <img
              src={require("./Images/phone.png")}
              className="ContactUsDataIconImg"
            />
          </div>
          <div className="ContactUsDatatext">
            <div className="ContactUsDatatextHead">Phone :</div>
            <div className="ContactUsDatatextValue">+971 XXXXXX XXX</div>
          </div>
        </div>
        <div className="ContactUsDataWrapper">
          <div className="ContactUsDataIcon">
            <img
              src={require("./Images/email.png")}
              className="ContactUsDataIconImg"
            />
          </div>
          <div className="ContactUsDatatext">
            <div className="ContactUsDatatextHead">Mail :</div>
            <div className="ContactUsDatatextValue">info@skyliftuae.com</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
