import React from "react";
import { ReactComponent as Brand } from "./Images/logo.svg";

function About() {
  return (
    <div className="AboutWrapper">
      <div className="aboutBackCover">
        <div className="aboutBox">
          <div className="aboutBoxLogo">
            {/* <Brand /> */}
            <img src={require("./Images/pnlogoslu.png")}  className="companylogo"/>
          </div>
          <div className="aboutBoxText">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </div>
        </div>
        <div className="aboutAddress">
          <div className="aboutAddressHead">ADDRESS</div>
          <div className="aboutRow">
            <div className="abouticon">
              <img
                src={require("./Images/location-white.png")}
                className="aboutImg"
              />
            </div>
            <div className="abouttext">Dubai, UAE.</div>
          </div>
          <div className="aboutRow">
            <div className="abouticon">
              <img
                src={require("./Images/phone-white.png")}
                className="aboutImg"
              />
            </div>
            <div className="abouttext">+971 XXXXXX XXX</div>
          </div>
          <div className="aboutRow">
            <div className="abouticon">
              <img
                src={require("./Images/email-white.png")}
                className="aboutImg"
              />
            </div>
            <div className="abouttext">info@skyliftuae.com</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
